<script>

// eslint-disable-next-line no-unused-vars
import Layout from "../../layouts/main";
// eslint-disable-next-line no-unused-vars
import PageHeader from "@/components/page-header";
const Jenjang = require('./../../../api/jenjang');
import Swal from "sweetalert2";

export default {
  // eslint-disable-next-line vue/no-unused-components
    components: { Layout, PageHeader },
    page: {
        title: "Jenjang Pendidikan",
        meta: [{ name: "description", content: "List Of Jenjang Pendidikan" }]
    },
    data() {
        return {
            title: "Jenjang Pendidikan",
            items: [
                {
                text: "Jenjang Pendidikan",
                active: true
                }
            ],
            lists : [],
            list : null,
            id:null,
            name :'',
            loading : false,
            state : 'list',
            isError :false,
            message : '',
            Jenjang : new Jenjang(),
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "jenjang",
            sortDesc: true,
            fields: [
                { key: "jenjang",label:"Jenjang Pendidikan", sortable: true },
                { key: "Actions", sortable: false }
            ]
        }
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            return this.lists.length;
        }
    },
    mounted(){
        this.getData();
        this.totalRows = this.items.length;
    },
    methods: {
        /**
         * Search the table data with search input
         */
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        getData(){
            this.Jenjang.data()
            .then((res)=>{
                if(!res.success){
                    this.setError(true,res.data)
                }else{
                    this.lists = res.data;
                    // this.showList();
                }
            })
            .catch((err)=>{
                this.setError(true,err);
            })
        },
        showCreate(){
            this.state = 'create'
        },
        showList(){
            this.state = 'list'
            this.getData();
            this.reset();
        },
        save(){
            if(this.name.length < 1){
                this.setError(true,"Jenjang name Min. 4 Character !")
            }else{
                if(this.id == null){
                    this.Jenjang.create({jenjang:[this.name]})
                    .then((res)=>{
                        if(!res.success){
                            this.setError(true,res.data)
                        }else{
                            this.message = res.data;
                            this.showList();
                        }
                    })
                    .catch((err)=>{
                        this.setError(true,err);
                    })
                }else{
                    this.Jenjang.edit({id:this.id,name:this.name})
                    .then((res)=>{
                        if(!res.success){
                            this.setError(true,res.data)
                        }else{
                            this.message = res.data;
                            this.showList();
                        }
                    })
                    .catch((err)=>{
                        this.setError(true,err);
                    })
                }
            }
        },
        setError(error,message){
            this.isError = error;
            this.message = message;
            this.loading = false;
        },
        edit(row){
            this.id = row.item.id
            this.name = row.item.jenjang
            this.showCreate()
        },
        reset(){
            this.id = 0;
            this.name = ''
            this.loading = false,
            this.message =''
            this.isError = false
        },
        hapus(row){
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-danger ml-2"
                },
                buttonsStyling: false
            });
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!"
            }).then(result => {
                if (result.value) {
                    this.Jenjang.hapus({id:row.item.id})
                    .then((res)=>{
                        this.getData()
                        if(res.success){
                            Swal.fire("Deleted!", "Your file has been deleted.", "success");
                        }else{
                            swalWithBootstrapButtons.fire(
                            "Cancelled",
                            res.data_area,
                            "error"
                            );
                        }
                    })
                    .catch((err)=>{
                            swalWithBootstrapButtons.fire(
                            "Cancelled",
                            err,
                            "error"
                            );
                    })
                }
            });
        }
    },
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
        <div class="col-md-12">
            <div class="card" v-show="state === 'list'">
                <div class="card-body">
                    <div class="d-flex flex-row justify-content-between align-items-center">
                        <button class="btn btn-success ml-2" @click="showCreate()"><i class="bx bx-plus-circle"></i> Add New</button>
                        <h4 class="card-title">Data Jenjang Pendidikan</h4>
                    </div>
                    <div class="row mt-4">
                        <div class="col-sm-8 col-md-6">
                            <div id="tickets-table_length" class="dataTables_length">
                                <label class="d-inline-flex align-items-center">
                                    Show&nbsp;
                                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                                </label>
                            </div>
                        </div>
                        <!-- Search -->
                        <div class="col-sm-12 col-md-6">
                            <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                <label class="d-inline-flex align-items-center">
                                    Search:
                                    <b-form-input
                                    v-model="filter"
                                    type="search"
                                    placeholder="Search..."
                                    class="form-control form-control-sm ml-2"
                                    ></b-form-input>
                                </label>
                            </div>
                        </div>
                        <!-- End search -->
                    </div>
                        <!-- Table -->
                    <div class="table-responsive mb-0">
                        <b-table
                            :items="lists"
                            :fields="fields"
                            responsive="sm"
                            :per-page="perPage"
                            :current-page="currentPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :filter="filter"
                            :filter-included-fields="filterOn"
                            @filtered="onFiltered"
                        >
                            <template #cell(Actions)="row">
                                <button class="btn btn-warning btn-sm mx-1" @click="edit(row)"  v-b-tooltip.hover title="Edit"><i class="bx bx-edit"></i></button>
                                <button class="btn btn-danger btn-sm  mx-1"  v-b-tooltip.hover title="Delete" @click="hapus(row)"><i class="bx bx-trash"></i></button>
                            </template>
                        </b-table>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="dataTables_paginate paging_simple_numbers float-right">
                            <ul class="pagination pagination-rounded mb-0">
                                <!-- pagination -->
                                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" v-show="state === 'create'">
                <div class="card-body">
                    <div class="d-flex flex-row justify-content-start align-items-center">
                        <a class="btn text-danger  font-size-34" @click="showList()"><i class="bx bx-left-arrow-alt"></i></a>
                        <h4 class="card-title">Add New Jenjang Pendidikan</h4>
                    </div>
                    <div class="row  d-flex flex-column justify-content-center align-items-center">
                        <div class="col-md-5">
                            <b-alert variant="danger" :show="isError">{{message}}</b-alert>
                            <div class="form-group">
                                <label class="control-label">Jenjang Pendidikan<span class="text-danger">*</span></label>
                                <input type="text" :class="isError ? 'form-control is-invalid' :'form-control'" v-model="name" placeholder="Jenjang Pendidikan">
                            </div>
                            <div class="form-group">
                                <button class="btn btn-sm btn-primary" @click="save()" :disabled="loading"> <i v-if="loading" class="bx bx-loader bx-spin"></i> {{loading ? 'Loading':'Save Data'}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </Layout>
</template>